/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "verdana";
  src: url("./assets/fonts/verdana.ttf");
}

@font-face {
  font-family: "verdana-bold";
  src: url("./assets/fonts/verdana-bold.ttf");
}

@font-face {
  font-family: "verdana-bitalic";
  src: url("./assets/fonts/verdana-bold-italic.ttf");
}

@font-face {
  font-family: "roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "roboto600";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "roboto900";
  src: url("./assets/fonts/Roboto-Black.ttf");
}

@import 'ngx-toastr/toastr';

* {
  font-family: "verdana";
  margin: 0px;
  padding: 0px;
}

@import "~@ng-select/ng-select/themes/default.theme.css";

.errcls {
  text-align: right;
  display: flex;
  width: 100%;
  margin-top: var(--font-size-15);
  justify-content: center;
  color: #ef0707;
  font-size: var(--font-size-14);
}

.dropDownList {
  border: 1px solid rgba(0, 0, 0, .10) !important;
}

.accordion.accordion-flush {
  .accordion-button {
    font-size: var(--font-size-18) !important;
    padding: var(--font-size-12);
  }

  .accordion-body {
    padding-top: 0px;
  }

  .u-add {
    margin-bottom: 0px;
  }

  .ulist-div {
    padding-top: var(--font-size-18);
  }
}

.evcres {
  font-size: var(--font-size-10);
}

.ng-select {
  padding: 0px !important;
}

.form-control:disabled {
  font-size: var(--font-size-16) !important;
}

.ngx-json-viewer .segment .segment-main {
  word-wrap: break-word;
  font-size: var(--font-size-16) !important;
}

input:not(.search-input):focus-visible {
  outline: none !important;
}

button:focus-visible,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

:root {
  --width-888: 888px;
  /*fonts*/

  --font-size-4: 4px;
  --font-size-5: 5px;
  --font-size-8: 8px;
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-28: 28px;
  --font-size-30: 30px;
  --font-size-32: 32px;
  --font-size-36: 36px;
  --font-size-38: 38px;
  --font-size-40: 40px;
  --font-size-45: 45px;
  --font-size-48: 48px;
  --font-size-50: 50px;
  --font-size-54: 54px;
  --font-size-56: 56px;
  --font-size-58: 58px;
  --font-size-60: 60px;
  --font-size-64: 64px;
  --font-size-70: 70px;
  --font-size-80: 80px;
  --font-size-90: 90px;
  /*width*/
  --width-115: 115px;
  --width-130: 130px;
  --width-200: 200px;
  --width-220: 220px;
  --width-235: 235px;
  --width-252: 252px;
  --width-260: 260px;
  --width-280: 280px;
  --width-290: 290px;
  --width-186: 186px;
  --width-150: 150px;
  --width-160: 160px;
  --width-300: 300px;
  --width-313: 313px;
  --width-324: 324px;
  --width-500: 500px;
  --width-350: 350px;
  --width-330: 330px;
  --width-400: 400px;
  --width-450: 450px;
  --width-640: 640px;
  --padding-104: 104px;
  /*height*/
  --height-213: 213px;
  --height-440: 440px;
  --height-312: 312px;


  --container-padding: 1.5rem;

  --clr-table-row: rgb(243,243,247);

  --clr-white:#fff;
  --clr-red: #CA441C;
  --clr-purple: #5367DE;
  --clr-light:hsl(222, 79%, 99%);
  --clr-primary-hsl: 222 79 15;
  
  
  --clr-primary-100:hsl(222, 79%, 90%);
  --clr-primary-200:hsl(222, 79%, 40%);
  --clr-primary-300: hsl(222, 58%, 40%);
  --clr-primary-350: hsl(222, 68%, 30%);
  --clr-primary:hsl(222, 79%, 15%);
  --clr-primary-500:hsl(222, 79%, 10%);

  --clr-secondary: #0091FF;
  --txt-100:hsl(222, 79%, 97%);
  --txt-400:hsl(222, 79%, 15%);
  --txt-500:#474554;
  --txt-700:hsl(222, 77%, 2%);

  --ff-primary: "verdana", 'sans-serif';
  --ff-primary-bold: "verdana-bold",'sans-serif';
  --ff-secondary: "roboto", 'sans-serif';
  --ff-secondary-bold: "roboto600", 'sans-serif';


  --fs-300:.9rem;
  --fs-400:1rem;
  --fs-600:1.3rem;
  --fs-700:1.5rem;
}

@media (max-width:768px) {
  :root {
    --fs-300:.765rem;
    --fs-400:.9rem;
    --fs-600:1.05rem;
    --fs-700:1.2rem;
  }
}



.txt-100 { color: var(--txt-100); }
.txt-400 { color: var(--txt-400); }
.txt-500 { color: var(--txt-500); }
.txt-700 { color: var(--txt-700); }

// ================== Buttons ======================
.p-container {
  padding: var(--container-padding);
}

.bg-light {
  background-color: var(--clr-light);
}

@media (max-width:768px) {
  .bg-sm-light {
    background-color: var(--clr-light);
  }
}
.bg-input {

  &:placeholder-shown {
    background-color: #00206005 !important;
  }

  background-color: white;

}

.btn {
  border-radius: .4em;
  padding: .5em 1.3em;
}

.btn-secondary {
  border-radius: .4em;
  padding: .4em 1em;
  color: var(--txt-500);
  border: 2px solid currentColor;
  background-color: transparent;
}

button.btn-secondary:focus-visible {
  outline: 2px solid currentColor !important;
  outline-offset: 0.3rem;
}

button.btn-primary:focus-visible {
  outline: 2px solid var(--clr-primary) !important;
  outline-offset: 0.3rem;
}


.btn-primary {
  border-radius: .4em;
  padding: .4em 1.1em;
  color: var(--txt-100);
  border: 2px solid var(--clr-primary-350);
  background-color: var(--clr-primary-350);

  &:focus-visible {
    outline: 2px solid currentColor;
    outline-offset: 0.3rem;
  }
}

.btn-table {
  background-color: transparent;
  border: 1px solid #5367DE;
  border-radius: 2px;
  color: #5367DE;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}


.dark-gray {
  color: #3F424B;
}


.ff-bold {
  font-family: "verdana-bold";
}

.nowrap {
  white-space: nowrap;
}

@media (min-width:768px) {
  .w-md-auto.w-md-auto {
    width: auto !important;
  }
}


// old variables in VH form
// :root {
//   --width-888: 61.6vw;
//   /*fonts*/

//   --font-size-4: 0.27vw;
//   --font-size-5: 0.34vw;
//   --font-size-8: 0.55vw;
//   --font-size-10: 0.69vw;
//   --font-size-12: 0.83vw;
//   --font-size-14: 0.98vw;
//   --font-size-15: 1.04vw;
//   --font-size-16: 1.12vw;
//   --font-size-17: 1.18vw;
//   --font-size-18: 1.25vw;
//   --font-size-20: 1.38vw;
//   --font-size-22: 1.52vw;
//   --font-size-24: 1.66vw;
//   --font-size-26: 1.80vw;
//   --font-size-28: 1.94vw;
//   --font-size-30: 2.08vw;
//   --font-size-32: 2.23vw;
//   --font-size-36: 2.5vw;
//   --font-size-38: 2.63vw;
//   --font-size-40: 2.77vw;
//   --font-size-45: 3.12vw;
//   --font-size-48: 3.34vw;
//   --font-size-50: 3.47vw;
//   --font-size-54: 3.75vw;
//   --font-size-56: 3.88vw;
//   --font-size-58: 4.02vw;
//   --font-size-60: 4.16vw;
//   --font-size-64: 4.44vw;
//   --font-size-70: 4.86vw;
//   --font-size-80: 5.55vw;
//   --font-size-90: 6.25vw;
//   /*width*/
//   --width-115: 7.98vw;
//   --width-130: 9.02vw;
//   --width-200: 13.88vw;
//   --width-220: 15.27vw;
//   --width-235: 16.31vw;
//   --width-252: 17.50vw;
//   --width-260: 18.05vw;
//   --width-280: 19.44vw;
//   --width-290: 20.13vw;
//   --width-186: 12.91vw;
//   --width-150: 10.41vw;
//   --width-160: 11.11vw;
//   --width-300: 20.83vw;
//   --width-313: 21.73vw;
//   --width-324: 22.50vw;
//   --width-500: 34.72vw;
//   --width-350: 24.30vw;
//   --width-330: 22.91vw;
//   --width-400: 27.7vw;
//   --width-450: 31.25vw;
//   --width-640: 44.44vw;
//   --padding-104: 7.22vw;
//   /*height*/
//   --height-213: 14.79vw;
//   --height-440: 30.55vw;
//   --height-312: 21.66vw;
// }


.badge {
  padding: 0.7em 1.2em !important;
  font-size: 0.7rem !important;
}

.top-bar .badge {
  padding: 0.5em !important;
  font-size: 0.68rem !important;
}

@media (max-width:768px) {
  .badge {
    padding: 0.765em !important;
    font-size: 0.7rem !important;
  }

  .top-bar .badge {
    padding: 0.456em !important;
    font-size: 0.5rem !important;
  }

}


.backBtn {
  padding: 2px 7px;
  margin-right: 20px;
  border: 1px solid var(--clr-primary-200);
  border-radius: .3em;
  background: none;

  cursor: pointer;
  i {
    display: block;
    scale: 0.85 1;
    font-size: 25px;
    color: var(--clr-primary-200);
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.blue {
  color: #0b0bbf !important;
  cursor: pointer;
}

.delbtn {
  background-color: #FA0000 !important;
}

.btn-close {
  padding: var(--font-size-10) !important;
  width: var(--font-size-18) !important;
  height: var(--font-size-18) !important;
  padding: 0px !important;
}

/*chat box*/
#window-ribbon header {
  height: auto !important;
  padding: var(--font-size-12) !important;
}

.win_close {
  width: var(--font-size-32) !important;
  height: var(--font-size-32) !important;
}

/*chat box ends here*/
.productModal .modal-body select,
.productModal .modal-body input {
  padding: var(--font-size-12) !important;
}

/*global bg*/
.add-user-inner,
.ulist-div,
.profile-inner {
  background-color: #fff !important;
}

.tdholder {
  align-items: center;

  .tdinner {
    flex-direction: column;
    text-align: left;

    span {
      font-family: "roboto600";
      font-size: var(--font-size-14);
      line-height: var(--font-size-16);
      display: flex;
      align-items: center;
      color: #3F424B;
    }

    p {
      font-family: "roboto";
      font-size: var(--font-size-14);
      line-height: var(--font-size-16);
      display: flex;
      align-items: center;
      color: #3F424B;
      margin-bottom: 0px;

    }
  }
}

/*global bg ends here*/
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 var(--font-size-30) white inset !important;
}


/*add user UI change*/
.addUser {
  display: flex;
  gap: var(--font-size-90);
}

.emaildiv {
  width: 100% !important;
}

/*BreadCrumb Css starts here*/

.breadcrumb {
  margin-bottom: 0px;
  border: 1px solid rgba(0, 32, 96, 0.2);
  box-shadow: 0px 4px 4px rgb(179 179 179 / 20%);
  border-radius: var(--font-size-10) !important;
  display: flex;
  align-items: center;
  background-color: #fff !important;
  padding: var(--font-size-12) var(--font-size-20) !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  content: "/" !important;
  margin: var(--font-size-10) var(--font-size-10) 0px var(--font-size-10);
  background-size: cover;
  margin-top: 5px;
  margin-right: 0px;
  font-size: 12px;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0px !important;
}

.breadcrumb li a {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-12);
  line-height: var(--font-size-15);
  color: #6F7784;
  font-family: "verdana";
  text-decoration: none;
}

.breadcrumb li a.active {
  color: #0E1321;
}

/*telinput strats here*/

.iti__country-list.iti__hide {
  display: none !important;
}

.iti__country-list {
  display: block !important;
  max-width: var(--width-313);
  overflow-x: hidden;
  z-index: 999 !important;
  max-height: var(--width-200) !important;
}

.iti__country-name,
.iti__dial-code,
.iti__selected-dial-code {
  font-size: var(--font-size-16) !important;
}

.profile-inner {
  border: 1px solid rgba(0, 32, 96, 0.2);
  border-radius: var(--font-size-12);
  width: 100%;
  padding: var(--font-size-24) var(--font-size-20);

  ul {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    gap: var(--font-size-60);
    border-bottom: 1px solid #E7E7E7;


    li {
      margin-bottom: var(--font-size-5);
      cursor: pointer;

      a {
        text-decoration: none;
      }
    }

  }
}

/*active-inactive tab*/
.tactive {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #002060;
  position: relative;
}


li.tactive::after {
  content: "";
  background: #002060;
  height: 2px;
  bottom: calc(-1 * var(--font-size-5));
  left: 0;
  width: 100%;
  position: absolute;
}

/*active-inactive tab ends here*/

/*active/inactive classes*/
.activecls {
  background: rgba(33, 150, 83, 0.1);
  border: 0.5px solid rgba(190, 202, 217, 0.2);
  border-radius: var(--font-size-20);
  font-family: "verdana";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-10);
  line-height: var(--font-size-12);
  color: #219653;
  padding: var(--font-size-5) var(--font-size-12);
}

.Inactivecls {
  background: rgba(255, 0, 0, 0.2);
  border: 0.5px solid rgba(255, 0, 0, 0.1);
  border-radius: 20px;
  font-family: "verdana";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-10);
  line-height: 12px;
  color: #FF0000;
  padding: var(--font-size-5) var(--font-size-12);
}

.holdcls {
  background: rgba(111, 119, 132, 0.17);
  border: 0.5px solid rgba(190, 202, 217, 0.2);
  border-radius: 20px;
  font-family: "verdana";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-10);
  line-height: 12px;
  color: #3F424B;
  padding: var(--font-size-5) var(--font-size-12);
}

/*ends here*/
.confirmbtn {
  background-color: #394A9C;
  color: #fff;
  font-size: var(--font-size-16);
  float: right;
  margin-top: var(--font-size-12);
}

.flexDiv {
  display: flex;
  justify-content: flex-end;
  gap: var(--font-size-20);
}

.ulist-div {
  border: 1px solid rgba(0, 32, 96, 0.2);
  border-radius: .5rem;
  width: 100%;
  padding: var(--font-size-24) var(--font-size-20);
  overflow-x: clip;
}

.eyeIcon.delIcon {
  color: #FA0000 !important;
  font-size: var(--font-size-12);
}

.edit {
  color: #6D7886 !important;
  font-size: 12px;
}

.eyeIcon {
  font-size: var(--font-size-12);
  color: #5367DE !important;
}

/*ngx pagination*/

.ngx-pagination .current,
.ngx-pagination li {
  width: var(--font-size-24) !important;
  height: var(--font-size-24) !important;
  background: #002060 !important;
  border-radius: 8px !important;
  line-height: var(--font-size-20) !important;
  padding: 0px !important;
  text-align: center;

  span {
    font-weight: 500;
    font-size: var(--font-size-10);
  }
}

.ngx-pagination {
  margin-top: var(--font-size-32);
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    background: rgba(0, 32, 96, 0.05) !important;
    margin-right: var(--font-size-12) !important;
    width: var(--font-size-24) !important;
    height: var(--font-size-24) !important;
    line-height: var(--font-size-20) !important;

    span {
      font-weight: 700;
      font-size: var(--font-size-10);
    }
  }

  a {
    padding: 0px !important;
  }
}

.eyeIcon.delIcon {
  border: 1px solid #FA0000 !important;
}

.profile-inner ul.ngx-pagination {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  gap: var(--font-size-4) !important;
  border-bottom: 1px solid #E7E7E7;
}

.ngx-pagination li.pagination-next,
.ngx-pagination li.pagination-previous {
  width: auto !important;
  height: auto !important;
  background: transparent !important;
  display: flex;
  align-items: center;

  a {
    padding: var(--font-size-10) !important;
    font-size: var(--font-size-12) !important;
  }
}

.toast-container {
  position: fixed !important;
  z-index: 9999 !important;
}

.zsiq_theme1.zsiq_floatmain,
.zsiq_theme1 .zsiq_flt_rel {
  width: 40px !important;
  height: 40px !important;
}

.zsiq_theme1 .siqicon:before {
  font-size: 20px !important;
  line-height: 40px !important;
}

a:focus-visible {
  outline: none !important;
}

.responsive-tbl table thead th {
  white-space: nowrap;
}

.responsive-tbl {
  max-height: 55vh;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: rgb(216, 221, 231) !important;
  }
}

/*custome pagination*/
.btn.pprev+p {
  margin-top: 3px !Important;
  display: flex;
  align-items: center;
}

.btn.pprev {
  color: #5367DE;
  border: 1px solid #5367DE;
  border-radius: 5px;
  font-size: var(--font-size-16);
  padding: var(--font-size-10);
}

.btn.pnext {
  color: #5367DE;
  border: 1px solid #5367DE;
  border-radius: 5px;
  font-size: var(--font-size-16);
  padding: var(--font-size-10);
}

.btn.pnext:hover {
  background-color: #5367DE;
  color: #fff;
}

/*custom pagination ends here*/
/*modal css*/
.absdivModal {
  position: relative;

  margin-bottom: var(--font-size-10);

  input {
    height: var(--font-size-48);
    margin-bottom: var(--font-size-18);
    /*added later*/
    font-size: var(--font-size-14);
    padding: var(--font-size-12);
  }
}

.modal-dialog {
  height: 100vh;
}

.modal-body {
  padding: 0px !important;

  h4 {
    font-size: var(--font-size-20);
    text-align: left;
  }

}

.modal-content {
  min-width: 0 !important;
  padding: var(--font-size-30) !important;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: var(--width-500);
  transform: translate(-50%, -50%);

  >input {
    height: var(--font-size-48);
    margin-bottom: var(--font-size-18);
  }

  >button {
    color: #394A9C;
    border: none;
    background-color: #ebebeb;
  }
}

.modal-header {
  padding: 0px !important;
  padding-bottom: var(--font-size-20) !important;
  border-bottom: none !important;

  .modal-title {
    color: #002060;
    font-family: "verdana-bold";
    font-size: var(--font-size-20) !important;
  }

}

/*end modal css*/
/*notification*/
.notification-inner {
  display: flex;
  justify-content: space-between;
  gap: var(--font-size-20);

  div {
    p {
      font-size: var(--font-size-14) !important;
    }
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0 0 36px #00000029;
    top: 25px !important;
    min-width: 416px;
    transition: all .35s linear;
    padding: var(--font-size-14);
  }

  h6 {
    font-size: var(--font-size-14);
    margin-bottom: var(--font-size-12);
    font-family: "roboto600";
  }

  p {
    font-size: var(--font-size-14) !important;
  }
}

/*upload pic*/
.upload-pic {
  padding: 0px var(--font-size-20);
  gap: var(--font-size-36);
  text-align: left;
  position: relative;

  >img {
    width: var(--padding-104);
    height: var(--padding-104);
    border-radius: 50%;
    object-fit: cover;
    margin-top: var(--font-size-40);
  }
}

.upload-pic>div {
  border-radius: var(--font-size-12);
  position: relative;
  width: var(--font-size-40);
  height: var(--font-size-40);
  position: absolute;
  cursor: pointer;
  bottom: -5px;
  left: var(--font-size-70);

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.uploadtext {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--font-size-20);
  color: #394A9C;
  height: var(--font-size-40);
  width: var(--font-size-40);
  border-radius: 50%;
  background: #394A9C;
  border: 1px solid #FFFFFF;

  >img {
    width: var(--font-size-20);
    height: var(--font-size-15);
    object-fit: cover;

  }

  p {
    margin-bottom: 0px;
    font-size: var(--font-size-18);
  }
}

/*upload pic ends here*/
.cheml {
  padding-right: 130px !important;
}

/*dropdown menu*/
.dropdown-menu {
  li {
    a {
      img {
        width:20px!important;
        height:20px!important;
        margin-right: var(--font-size-10) !important;
        object-fit: contain !important;
      }
    }
  }
}

/*Auth common Css*/
.login-box {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

@media (max-width:720px) {
    .login-box {
      width: min(20rem, 90%);
      margin-inline: auto;
    }
}

.remember-me {
  display: flex;
}

.remember-me>div {
  width: 50%;
}

.bnrimg {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}

.reg {
  span {
    font-size: var(--font-size-16);
    line-height: var(--font-size-20);
    color: #3F424B;
    display: inline-block;
    margin-right: var(--font-size-10);
  }

  a {
    font-size: var(--font-size-16);
    line-height: var(--font-size-20);
    color: #00C0F2 !important;
    text-decoration: none;
  }
}

.login-box-inner {
  width: 100%;
  padding: var(--padding-104);

  .lbtn {
    font-family: "verdana-bold";
    font-size: var(--font-size-16);
    line-height: var(--font-size-20);
    color: #FFFFFF;
    padding: var(--font-size-16);
    border: none;
    background: #00C0F2 !important;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }

  form>div.f-margins {
    margin-bottom: var(--font-size-26);
  }

  form>div {
    margin-bottom: var(--font-size-16);
    position: relative;

    label {
      color: #3F424B;
      font-size: var(--font-size-16);
      margin-bottom: var(--font-size-12) !important;
    }

    .form-control {
      padding: var(--font-size-16);
      border: 1px solid #EBEBED;
      border-radius: 5px !important;
      font-size: var(--font-size-14);
      line-height: var(--font-size-16);
      color: #3F424B;

    }

  }

  h2 {
    font-family: "verdana-bold";
    font-size: var(--font-size-30);
    line-height: var(--font-size-30);
    color: #0E1321;
    margin-bottom: var(--font-size-16);
  }

  h2+p {
    font-size: var(--font-size-16);
    line-height: 19px;
    color: #3F424B;
    margin-bottom: var(--font-size-26);
  }
}

@media (max-width:768px) {
  .login-box-inner {
    padding: 1rem;
  }
}

.InputContainer .InputElement {
  font-size: var(--font-size-12) !important;
}

.responsive-tbl table .edit .fa {
  font-size: var(--font-size-18);
}

.responsive-tbl table .eyeIcon .fa,
.emaildynamic .fa {
  font-size: var(--font-size-18) !important;
}

.modal-footer {
  margin: var(--font-size-15) 0px;
}


.btn-close {
  background-size: var(--font-size-15) !important;
}

/*Auth css ends here*/
.text-danger {
  font-size: var(--font-size-16);
  margin-top: var(--font-size-5);
  display: inline-block;
}

small.text-danger {
  font-size: 0.875rem;
}

.offcanvas.show {
  input[type='checkbox'] {
    width: var(--font-size-14);
    height: var(--font-size-14);
  }

  >div {

    select,
    input {
      padding: var(--font-size-12);
      border-radius: var(--font-size-8);
    }
  }
}

.dropDownList {
  padding: var(--font-size-10) !important;

  li {
    input[type='checkbox'] {
      width: var(--font-size-12);
      height: var(--font-size-12);
      margin-right: var(--font-size-12) !important;
    }

    label {
      font-size: var(--font-size-12);
    }
  }
}

.v-align {
  .modal-body {
    p {
      font-size: var(--font-size-14) !important;
    }
  }
}

@media screen and (max-width:991px) {
  .text-danger {
    font-size: 12px;
  }

  .errcls {
    font-size: 12px;
  }

  .offcanvas-title {
    font-size: 16px !important;
  }

  .v-align {
    .modal-body {
      p {
        font-size: 14px !important;
      }
    }
  }

  .dropDownList {
    padding: 10px !important;

    li {
      input[type='checkbox'] {
        width: 12px;
        height: 12px;
        margin-right: 10px !important;
      }

      label {
        font-size: 12px;
      }
    }
  }

  .offcanvas.show {
    input[type='checkbox'] {
      width: 14px;
      height: 14px;
    }

    >div {

      select,
      input {
        padding: 12px;
      }
    }
  }

  .btn-close {
    background-size: 14px !important;
    width: 18px !important;
    height: 18px !important;
  }

  .offcanvas-header {
    .btn-close {
      background-size: 14px !important;
      width: 18px !important;
      height: 18px !important;
    }
  }

  .offcanvas-title {
    line-height: normal !important;
  }

  :root {
    --font-size-4: 4px;
    --font-size-5: 5px;
    --font-size-8: 8px;
    --font-size-10: 10px;
    --font-size-12: 12px;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-17: 17px;
    --font-size-18: 18px;
    --font-size-20: 20px;
    --font-size-24: 24px;
    --font-size-26: 26px;
    --font-size-30: 30px;
    --font-size-32: 32px;
    --font-size-36: 36px;
    --font-size-38: 38px;
    --font-size-40: 40px;
    --font-size-48: 48px;
    --font-size-50: 50px;
    --font-size-56: 56px;
    --font-size-60: 60px;
    --font-size-64: 64px;
    /*width*/
    --width-260: 260px;
    --width-186: 186px;
    --width-150: 150px;
    --width-160: 160px;
    --width-350: 350px;
  }



  .side-bar .menu ul li a {
    font-size: var(--fs-400) !important;

    span {
      font-size: var(--fs-400) !important;
    }
  }

  .ulist-div,
  .permission-inner {
    padding-top: 10px !important;
  }

  .submitReset td {
    font-size: 12px !important;
  }

  .login-box-inner {
    input {
      padding: 0px !important;
    }

    h2 {
      margin-bottom: 15px !important;
      font-size: 18px !important;
    }

    h2+p {
      margin-bottom: 15px !important;
      font-size: 14px !important;
    }

    form>div .form-control {
      padding: var(--font-size-12) !important;
    }

    .lbtn {
      padding: var(--font-size-12) !important;
    }
  }

  .reg span {
    font-size: 14px !important;
  }

  .top-bar .notification img {
    width: 18px !important;
    height: auto !important;
  }

  .u-add h2 {
    font-size: 18px !important;
  }

  .u-div h2 {
    font-size: 18px !important;
  }

  .addUser {
    flex-direction: column;
    gap: 15px;
  }

  .upload-pic>img {
    margin-top: 10px;
  }

  .ngx-pagination li.pagination-next,
  .ngx-pagination li.pagination-previous {
    a {
      padding: 10px !important;
      font-size: 12px !important;
    }
  }

  .ngx-pagination .current,
  .ngx-pagination li {
    width: 20px !important;
    height: 20px !important;
    // background: #002060 !important;
    border-radius: 8px !important;
    line-height: normal !important;
    padding: 0px !important;
  }

  .ng-select .ng-select-container {
    min-height: auto !important;
  }

  .ng-value-container {
    input {
      padding: 0px !important;
    }
  }

  .responsive-tbl table .eyeIcon .fa,
  .emaildynamic .fa {
    font-size: 14px !important;
  }
}

@media screen and (max-width:767px) {
  .menu-btn {
    order: 3 !important;
  }

  .profile {
    order: 1 !important;
  }

  .notification {
    // order: 2 !important;
  }

  .side-bar {
    left: -100% !important;
    display: block;
    width: 100vw !important;
    z-index: 9999;
    transition: all 200ms ease-in-out;

    &.active {
      left: 0 !important;
    }

    .log {
      img {
        width: auto !important;
      }
    }
  }

  .top-bar {
    left: 0 !Important;
  }

  .right-area {
    padding-left: 0px !important;
  }

  h2 {
    font-size: 18px !important;
  }

  .responsive-tbl {
    thead {
      th {
        font-size: 12px !important;
        p {
          font-size: var(--fs-400) !important;
        }
      }
    }

    td {
      font-size: 12px !important;
    }
  }

  a,
  p,
  button {
    font-size: 14px;
  }

  label {
    font-size: .9rem !important;
  }

  input,
  select,
  textarea {
    padding: 10px !important;
    font-size: 12px !important;
  }

  .upload-pic {
    >img {
      width: 90px !important;
      height: 90px !important;
    }
  }

  .humburger {
    display: none;
  }


  .righttop .menu-btn {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    display: grid;
    place-content: center;
    padding: .5rem;
  }

  .menu-btn {
    margin-left: .5em;
   

  

    cursor: pointer;
    
    svg {
      width: 18px;
      height: 18px;
    }

    svg.close {
      width: 28px;
      height: 28px;
    }

  }

  .menu-btn>span:nth-child(1) {
    position: absolute;
    top: 5px;
    height: 1px;
    background-color: #002060;
    border-radius: 50px;
    width: 100%;
  }

  .menu-btn>span {
    position: absolute;
    height: 1px;
    background-color: #002060;

  }

  .menu-btn>span:nth-child(1) {

    top: 5px;
    border-radius: 50px;
    width: 100%;
  }

  .menu-btn>span:nth-child(2) {

    top: 12px;
    border-radius: 50px;
    width: 100%;
  }

  .menu-btn>span:nth-child(3) {

    top: 20px;
    border-radius: 50px;
    width: 100%;
  }

  .side-bar {
    width: 100% !important;

    .logo {
      margin: 10px 15px 35px 15px;
      margin-bottom: 35px !important;
    }

    .menu ul>li a {
      padding: 0.765rem 15px !important;
    }

    .logo {
      img {
        height: auto !important;
        object-fit: cover;
      }
    }


  }

  .top-bar .profile > img:nth-child(1) {
    width: 30px !important;
    height: 30px !important;
  }

  .top-bar .profile {
    align-items: center;
  }

  .top-bar {

    padding: 10px 20px !important;

  }


}

@media screen and (max-width:767px) {
  .kbase {
    div {
      * {
        font-size: 16px !important;
        white-space: break-spaces;
      }

      table {
        width: 100% !important;
      }
    }
  }

  .iti.iti--allow-dropdown.iti--separate-dial-code {
    padding-left: 73px !important;
  }

  .iti__selected-dial-code {
    font-size: 12px !important;
  }

  .modal-content {
    padding: 15px !important;
  }
}

@media screen and (max-width:720px) {
  .mob-left {
    width: 100% !important;
    opacity: .3;
  }

  .mob-right {
    width: 100% !important;
    position: absolute;
  }

  .login-box-inner {

    h2 {
      margin-bottom: 0px !important;
      font-size: 18px !important;
    }
  }

  .productModal .modal-header h4 {
    font-size: 16px !important;
  }

  .modal-header .modal-title {
    font-size: 15px !important;
  }
}

@media screen and (max-width:520px) {
  .ngx-pagination {
    padding-left: 0px !important;
  }

  .modal-footer {
    button {
      width: 100%;
    }
  }

  .iti__country-list {
    max-width: 85%;
    max-height: 200px;
  }

  .profile-inner {
    padding: 15px;
  }

  h2 {
    font-size: 16px !important;
    margin-bottom: 0px;
  }

  .u-add h2 {
    font-size: 16px !important;
  }

  .u-div h2 {
    font-size: 16px !important;
  }

  .offcanvas.show h1,
  .offcanvas.showing h1 {
    font-size: 16px !important;
  }
}

@media screen and (max-width:380px) {
  .login-box-inner {
    form>div {
      flex-direction: column;
    }
  }

  .remember-me>div {
    width: 100% !important;
  }

  .align-right {
    text-align: left !important;
    margin-top: 10px;
  }

  .top-bar {
    justify-content: flex-end !important;
    gap: 18px !important;
  }

}


/** scroll bar customize***/
* {
  scrollbar-width: thin;
  scrollbar-color: #002060 #ffffff;

}

// / Chrome, Edge, and Safari /
*::-webkit-scrollbar {
  width: 4px;

}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a4afc7;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

/** scroll bar customize end***/
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999999 !important;
  position: absolute;
  height: 270px;
  right: 30px;
  top: 310px;
  background: white;
}

.iti {
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type="text"][readonly] {
  background-color: #f0f0f0;
  color: #888;
  cursor: not-allowed;
}

textarea[readonly] {
  background-color: #f0f0f0;
  color: #888;
  cursor: not-allowed;
}


.tableHeadAction {
  position: sticky !important;
  right: 0px !important;
  z-index: 1 !important;
  background: rgb(216 221 231) !important;
}

.tableBodyAction {
  position: sticky !important;
  right: 0px !important;
  background: white !important;
}

.breadcrumb {
  display: none !important;
}

input.mat-mdc-chip-input {
  font-size: var(--font-size-16);
  padding: var(--font-soze-16);
}

.ng-select.ng-select-single .ng-select-container {
  height: auto !important;
  padding: var(--font-size-14) var(--font-size-16);
}

@media (max-width:768px) {
  .ng-select.ng-select-single .ng-select-container {
    padding: 8px 6px !important;
  }
}


.ng-select span {
  font-size: var(--font-size-16) !important;
}

.ng-select .ng-select-container {
  // border: none !important;
}

.ng-option-label {
  font-size: var(--font-size-12) !important;

  img {
    width: var(--font-size-14) !important;
    height: var(--font-size-14) !important;
  }
}

.no-data {
  font-size: var(--font-size-12);
}

/*master setting*/
@media screen and (max-width:991px) {
  .no-data {
    font-size: 12px;
  }


  .ng-select span {
    font-size: 12px !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 4px !important;
  }

  .ng-select:not(.custom).ng-select-single .ng-select-container {
    padding: 0px !important;
  }

  .register-box-inner form>div .sel_country {
    padding: 10px !important;
  }

  .ng-option-label {
    font-size: 12px !important;

    img {
      width: 14px !important;
      height: 14px !important;
    }
  }

  .ulist-div {
    padding: 15px !important;
  }

  .productModal .modal-footer {
    position: relative !important;
    margin-top: 40px;
    padding: 20px !important;

    button {
      width: 80%;
      margin: auto;
    }
  }

  .productModal .modal-body {
    height: auto !important;
  }

}

@media screen and (max-width:520px) {
  .modal-header .btn-close {
    font-size: 10px !important
  }

  .modal-heade {
    margin-bottom: 0px !important;
  }
}

.pointer {
  cursor: pointer;
}

// canvas {
//   height: 200px !important; /* Set your custom height here */
//   width: 100% !important; /* Set your custom width here */
// }

.status_closed{
  margin-bottom: 0px;
  border: 1px solid green;
  color: green;
  padding: 2px 5px;
  border-radius: 15px;
  text-align: center;
}
.status_open{
  margin-bottom: 0px;
  border: 1px solid rgb(70, 110, 241);
  color: rgb(70, 110, 241);
  padding: 2px 5px;
  border-radius: 15px;
  text-align: center;
}
.status_mh{
  margin-bottom: 0px;
  border: 1px solid rgb(243, 80, 80);
  color: rgb(243, 80, 80);
  padding: 2px 15px;
  border-radius: 15px;
}
.chartDateSize{
  font-size: 14px;
}
.offcanvas-header {
  justify-content: space-between !important;
}

.cke_notification {
  display: none;
}

.responsive-tbl {
  border: 1px solid rgba(0, 32, 96, 0.15);
}

table {
  border: 1px solid rgba(0, 32, 96, 0.15);
}

.responsive-tbl > table {
  border: none;
}

.u-add {
  h2 {
    margin-bottom: 0;
  }
  & + hr {
    margin-inline: 0 !important;
  } 
}

.modal-dialog.files {
  max-width: 60ch;
}

.download.modal-dialog {
  max-width: min(90%, 100ch);
  > div {


    .modal-body {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}

a.hidden {
  display: inline-block;
  height: 0;
  width: 0;
  visibility: hidden;
}


.btn-icon.btn-icon {
  background-color: transparent;
  padding: .5em 1em;
  border-radius: .3em;
  border: 1px solid #708090 !important;
}
  

.absolute-hidden {
  visibility: hidden;
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
}


.main-title {
  font-size: 1.5rem;
  font-family: var(--ff-primary);
  font-weight: 700;
  color: var(--txt-500);
  margin-bottom: 0;
}

@media (max-width:768px) {
  .main-title {
    font-size: 1.1rem !important;
  }
}

.after-seperator {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: -10%;
    bottom: -0.7em;
    width: 120%;
    height: 2px;
    background-color: color-mix(in oklch,var(--txt-500), white 90%);
  }
}

.animated-dropdown.show {
  animation: fade-in 180ms forwards;
}

.animated-dropdown {
  animation: fade-out 180ms;
  transform: translateY(0rem);
  inset: 0 0 auto auto !important;
} 

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(0rem);
  }

  to {
    opacity: 1;
    transform: translateY(1rem);
  }
}

@keyframes fade-out {
  from {
    display: block;
    opacity: 1;
    transform: translateY(1rem);
  }
  to {
    display: none;
    opacity: 0;
    transform: translateY(0rem);
  }
}

// Page Styles 
.page-container {
  // background-color: var(--clr-light);
  border-radius: 0.5em;
  padding: 1.5rem;
  overflow-x: hidden;

  .main-title {
    text-align: left;
    margin-bottom: 1.5rem;
  }
}

.no-data-fallback {
  color: var(--txt-500);
  opacity: .8;
  font-size: 1.2rem;
  height: 40vh;
  display: grid;
  place-content: center;
  background-color: var(--clr-light);
}


.tab-list {

  --_br:0.5em;


  display: flex !important;
  gap: 0 !important;
  list-style: none;
  margin-block: 0.9em 0 !important;
  padding-left: 1.5em !important;

  position: relative;
  border-bottom: 0 !important;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 2px;
    background-color: color-mix(in oklch,var(--txt-500), white 90%);;
  }

  li {
    font-size: var(--fs-600);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: .4em .9em 0.5em;

    // border: 1px solid gray;
    margin-bottom: 0 !important;
    border-top-left-radius: var(--_br);
    border-top-right-radius: var(--_br);
    cursor: pointer;
  }

  li.active {
    color: var(--clr-primary);
    font-weight: 600;

    border-bottom: 3px solid var(--clr-primary);
    z-index: 2;
  }
}

@media (max-width:768px) {

  .tab-list {
    --_depth:55%;
    --_br-clr:color-mix(in oklch, var(--txt-500), white 90%);

    margin-block: 0 !important;
    padding-top: 0.9em;
    background-color: var(--clr-light);


    li.active {

      // &::before,
      // &::after {
      //   content: "";
      //   position: absolute;
      //   z-index: 3;
      //   right: calc(100% - 1px);
      //   bottom: -4px;
  
      //   height: calc(var(--_br) + 6px);
      //   aspect-ratio: 1;
      //   background-image: radial-gradient(
      //     circle at 0% 0% ,
      //     var(--clr-light) var(--_depth),
      //     var(--_br-clr) calc(var(--_depth)),
      //     white calc(var(--_depth) + 3px));
      // }
  
      // &::after {
      //   right: 0%;
      //   transform: translateY(-100%);
      //   rotate: 90deg;
      // }
    }
  
  
    li {
      position: relative;
      background-color: var(--clr-light);
      padding: 0.3em 0.9em 0.3em !important;
      border: 2px solid var(--_br-clr);
    }
  
  
  
    li.active {
      z-index: 2 !important;
      border-bottom: 3px solid white !important;
      background-color: white;
    }
  }

}




.mobile-only {
  display: none;
}

@media (max-width:768px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none !important;
  }
  
}


.table-theme {
  height: var(--width-350) !important;
  overflow: auto !important;
  border: 1px solid rgba(0, 32, 96, 0.15);
  box-shadow: inset 0px 0px rgb(216, 221, 231);

  table {
    width: 100%;
    th,td {
      padding: 1em .9em;
      font-size: var(--fs-300);
      white-space: nowrap;
      color: var(--txt-500);

      p {
        font-size: inherit;
      }

      &:first-child {
        padding-inline-start: 2em;
      }
      
    }

    td {
      font-family: var(--ff-secondary);
    }

    th{
      padding-block: .7em;

      // Change headers font family (changing ff for i fill cause issue )
      *:not(i) {  
        // font-family: var(--ff-primary-bold);
      }
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: rgb(216, 221, 231) !important;
      box-shadow: 0 -2px 0 5px rgb(216, 221, 231);
    }

    tr:nth-child(odd) {
      background-color: var(--clr-table-row);
      border-block:1px solid #B3B3B3;
    }

    tr:nth-child(even) {
      background-color: var(--clr-white);
      border-block:1px solid #B3B3B3;
    }


    .tableBodyAction {
      background-color: inherit !important;

       .icon {
        font-size: var(--fs-400);
        border: 1px solid hsl(var(--clr-primary-hsl));
      }

      .eyeIcon {
         width: var(--font-size-26);
         height: var(--font-size-26);
         padding: var(--font-size-5);
         border: 1px solid #5367DE;
         border-radius: 2px;
         display: flex;
         align-items: center;
         justify-content: center;
         text-decoration: none;
         color: #5367DE;
        }
    }
  }
}

@media screen and (max-width:768px) {
  .table-theme {
    table {

      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        background-color: inherit;
      }

      .tableHeadAction,
      .tableBodyAction {
        position: static !important;
      }
    }

  }
}


  /* HTML: <div class="loader"></div> */
  .loader {
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      inset: 50% auto auto 50%;
      translate: -50% -50%;
      aspect-ratio: 2;
      z-index: 3;

  
      width: 50px;
      padding: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: var(--clr-primary-300);
      --_m: 
        conic-gradient(#0000 10%,#000),
        linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
              mask: var(--_m);
      -webkit-mask-composite: source-out;
              mask-composite: subtract;
      animation: l3 1s infinite linear;
      
    }
  
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
      background-color: rgb(255 255 255 / 44%);
      border-radius: var(--font-size-10);
    }
  }
  @keyframes l3 {
      20%{background-position:0%   0%, 50%  50%,100%  50%}
      40%{background-position:0% 100%, 50%   0%,100%  50%}
      60%{background-position:0%  50%, 50% 100%,100%   0%}
      80%{background-position:0%  50%, 50%  50%,100% 100%}
  }
  
  
  
  @keyframes l27 {
    100% {transform: rotate(1turn)}
  }
  
  
  @keyframes l3 {to{transform: rotate(1turn)}}